exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-become-a-supplier-callback-tsx": () => import("./../../../src/pages/become-a-supplier/callback.tsx" /* webpackChunkName: "component---src-pages-become-a-supplier-callback-tsx" */),
  "component---src-pages-become-a-supplier-thank-you-tsx": () => import("./../../../src/pages/become-a-supplier/thank-you.tsx" /* webpackChunkName: "component---src-pages-become-a-supplier-thank-you-tsx" */),
  "component---src-pages-contact-us-thank-you-tsx": () => import("./../../../src/pages/contact-us/thank-you.tsx" /* webpackChunkName: "component---src-pages-contact-us-thank-you-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-join-us-callback-tsx": () => import("./../../../src/pages/join-us/callback.tsx" /* webpackChunkName: "component---src-pages-join-us-callback-tsx" */),
  "component---src-pages-join-us-online-tsx": () => import("./../../../src/pages/join-us/online.tsx" /* webpackChunkName: "component---src-pages-join-us-online-tsx" */),
  "component---src-pages-join-us-thank-you-tsx": () => import("./../../../src/pages/join-us/thank-you.tsx" /* webpackChunkName: "component---src-pages-join-us-thank-you-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-portal-tsx": () => import("./../../../src/pages/portal/[...].tsx" /* webpackChunkName: "component---src-pages-portal-tsx" */),
  "component---src-pages-preview-page-tsx": () => import("./../../../src/pages/preview-page.tsx" /* webpackChunkName: "component---src-pages-preview-page-tsx" */),
  "component---src-pages-preview-post-tsx": () => import("./../../../src/pages/preview-post.tsx" /* webpackChunkName: "component---src-pages-preview-post-tsx" */),
  "component---src-pages-set-password-tsx": () => import("./../../../src/pages/set-password.tsx" /* webpackChunkName: "component---src-pages-set-password-tsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

