import { useMutation } from "@apollo/client"
import { navigate } from "gatsby"
import { Slide, toast } from "react-toastify"
import { LOG_OUT } from "~/components/auth/LogOut"
import { useUserStore } from "~/lib/stores/user"

export default function useSessionEnd() {
  const setUserSessionStart = useUserStore(state => state.setUserSessionStart)
  const setUserLoggedIn = useUserStore(state => state.setUserLoggedIn)
  const removeUser = useUserStore(state => state.removeUser)
  const [logOut] = useMutation(LOG_OUT)

  // on session end, remove user, set logged in to false, and redirect to login
  return {
    endSession: () => {
      // remove user from store
      removeUser()
      // set logged in to false
      setUserLoggedIn(false)
      // unset session start date
      setUserSessionStart(null)
      // log out
      logOut()
    },
    sessionEnded: () => {
      setTimeout(() => navigate("/"), 100)
      toast.success("You successfully logged out", {
        transition: Slide,
        autoClose: 1500,
        hideProgressBar: true,
        onOpen: () => {
          navigate("/")
        },
      })
    },
    sessionExpired: () => {
      toast.error("Your session has expired. Please log in again.", {
        transition: Slide,
        autoClose: 1500,
        hideProgressBar: true,
        onOpen: () => {
          navigate("/portal/login")
        },
      })
    },
  }
}
