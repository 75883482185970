import React from "react"
import parse from "html-react-parser"
import clsx from "clsx"
import Link from "../Link"
import { frontendUrl } from "~/lib/constants"

type Styles = {
  [index: string]: string
}

export type ButtonColor =
  | "orange"
  | "yellow"
  | "green"
  | "light-green"
  | "light-orange"

interface BaseProps {
  text: string | JSX.Element
  buttonColor: ButtonColor
  className?: string
  buttonStyle?: string
}
interface ButtonProps extends BaseProps {
  type: "button" | "submit" | "reset"
  disabled?: boolean
  onClick?: () => void
}
interface ButtonLinkProps extends BaseProps {
  link: string
}

const defaultStyles = `brew-button relative mx-3 mb-3 mt-1 inline-block rounded-lg border-4 border-solid px-6 lg:px-8 py-2 lg:py-3 text-[18px] font-bold leading-normal text-theme-black transition-all primary hover:bg-transparent [&_.before]:h-1 [&_.after]:h-1`

const baseStyles: Styles = {
  primary: `${defaultStyles} shadow-[0_8px_32px_rgb(0_0_0_/_16%)] hover:bg-transparent hover:shadow-none`,
  secondary: `${defaultStyles} bg-transparent shadow-none before:top-[-3px] before:h-[3px] after:bottom-[-3px] after:h-[3px] hover:shadow-[0_8px_32px_rgb(0_0_0_/_16%)] hover:before:top-[-7px] hover:before:h-[6px] hover:after:bottom-[-7px] hover:after:h-[6px]`,
}

const variantStyles: { [index: string]: Styles } = {
  primary: {
    green: `border-theme-green before:bg-theme-green after:bg-theme-green bg-theme-green`,
    orange: `border-theme-orange before:bg-theme-orange after:bg-theme-orange bg-theme-orange`,
    "light-orange": `border-theme-orange-light before:bg-theme-orange-light after:bg-theme-orange-light bg-theme-orange-light`,
    yellow: `border-theme-yellow before:bg-theme-yellow after:bg-theme-yellow bg-theme-yellow`,
    "light-green": `border-theme-green-lighter before:bg-theme-green-lighter after:bg-theme-green-lighter bg-theme-green-lighter`,
  },
  secondary: {
    green: `border-theme-green before:bg-theme-green after:bg-theme-green hover:bg-theme-green`,
    orange: `border-theme-orange before:bg-theme-orange after:bg-theme-orange hover:bg-theme-orange`,
    "light-orange": `border-theme-orange-light before:bg-theme-orange-light after:bg-theme-orange-light hover:bg-theme-orange-light`,
    yellow: `border-theme-yellow before:bg-theme-yellow after:bg-theme-yellow hover:bg-theme-yellow`,
    "light-green": `border-theme-green-lighter before:bg-theme-green-lighter after:bg-theme-green-lighter hover:bg-theme-green-lighter`,
  },
}

const colorStyles: { [index: string]: Styles } = {
  fill: {
    green: `fill-theme-green`,
    orange: `fill-theme-orange`,
    "light-orange": `fill-theme-orange-light`,
    yellow: `fill-theme-yellow`,
    "light-green": `fill-theme-green-lighter`,
  },
}

export default function Button({
  text,
  buttonStyle = "primary",
  className,
  buttonColor = "orange",
  type = "button",
  disabled = false,
  onClick,
}: ButtonProps) {
  return (
    <button
      className={clsx(
        baseStyles[buttonStyle],
        variantStyles[buttonStyle][buttonColor],
        className || ""
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 427.8 6.5"
        preserveAspectRatio="none"
        className={`before absolute bottom-[calc(100%_+_4px)] left-0 right-0 transition-all`}
      >
        <path
          d="M427.7,6.5H0.1c0,0,99.8-6.5,206.7-6.5S427.7,6.5,427.7,6.5z"
          className={colorStyles.fill[buttonColor]}
        />
      </svg>
      <span className={``}>
        {typeof text === "string" ? parse(text || "") : text}
      </span>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 427.8 6.5"
        preserveAspectRatio="none"
        className={`after absolute top-[calc(100%_+_4px)] left-0 right-0 rotate-180 transition-all`}
      >
        <path
          d="M427.7,6.5H0.1c0,0,99.8-6.5,206.7-6.5S427.7,6.5,427.7,6.5z"
          className={colorStyles.fill[buttonColor]}
        />
      </svg>
    </button>
  )
}

export function ButtonLink({
  link,
  text,
  buttonStyle = "primary",
  className,
  buttonColor = "orange",
}: ButtonLinkProps) {
  return (
    <Link
      to={link.replace(frontendUrl, "")}
      className={clsx(
        baseStyles[buttonStyle],
        variantStyles[buttonStyle][buttonColor],
        className || ""
      )}
      activeClassName={undefined}
      forceBlank={undefined}
    >
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 427.8 6.5"
        preserveAspectRatio="none"
        className={`before absolute bottom-[calc(100%_+_4px)] left-0 right-0 transition-all`}
      >
        <path
          d="M427.7,6.5H0.1c0,0,99.8-6.5,206.7-6.5S427.7,6.5,427.7,6.5z"
          className={colorStyles.fill[buttonColor]}
        />
      </svg>
      <span className={``}>
        {typeof text === "string" ? parse(text || "") : text}
      </span>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 427.8 6.5"
        preserveAspectRatio="none"
        className={`after absolute top-[calc(100%_+_4px)] left-0 right-0 rotate-180 transition-all`}
      >
        <path
          d="M427.7,6.5H0.1c0,0,99.8-6.5,206.7-6.5S427.7,6.5,427.7,6.5z"
          className={colorStyles.fill[buttonColor]}
        />
      </svg>
    </Link>
  )
}
