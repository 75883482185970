const trackEvent = (event) => {
    if (typeof window !== `undefined`) {
        let data = window.dataLayer;
        if (data) data.push(event);
    }
};

export const trackOutboundLink = (url) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'outbound-link',
        customAction: 'click',
        customLabel: url,
        customValue: ''
    });
};

export const trackFormSubmit = (formTitle) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'form-submit',
        customAction: 'click',
        customLabel: formTitle,
        customValue: ''
    });
};

export const trackAdvertClick = (advertUrl) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'advert-click',
        customAction: 'click',
        customLabel: advertUrl,
        customValue: ''
    });
};
