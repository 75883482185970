export const restApiUrl = process.env.GATSBY_REST_API_URL ?? ""

export const signUpPage = `/join-us`
export const loginPage = `/login`
export const membersPortal = `/portal/members`
export const suppliersPortal = `/portal/suppliers`
export const frontendUrl = process.env.GATSBY_FRONTEND_URL ?? ""
export const backendUrl = process.env.GATSBY_BACKEND_URL ?? ""
export const netNetTokenUrl = `https://vetshare.rebatesvi.com/api/Token/get?clinicId=`

export const maxSessionLength = 1000 * 60 * 60 * 24 // 24 hours
