import React from "react"
import ClipLoader from "react-spinners/ClipLoader"

type LoaderProps = {
  loading: boolean
  fill?: boolean
  size?: number
}

const Loader = ({ loading, fill, size = 120 }: LoaderProps) => {
  if (!loading) return null

  if (fill) {
    return (
      <div className="absolute inset-0 z-50 bg-white/90 text-orange-500 backdrop-blur-sm">
        <div className="inset-0 z-50 flex h-[calc(100vh_-_10rem)] items-center justify-center">
          <ClipLoader size={size} color={"currentColor"} loading={loading} />
        </div>
      </div>
    )
  }
  return <ClipLoader size={size} color={"currentColor"} loading={loading} />
}

export default Loader
