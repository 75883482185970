import { create } from "zustand"
import { persist } from "zustand/middleware"
import { User } from "~/utils/types"

interface UserState {
  user: User | null
  setUser: (user: User) => void
  removeUser: () => void
  userLoggedIn: boolean
  setUserLoggedIn: (state: boolean) => void
  userSessionStart: Date | null
  setUserSessionStart: (date: Date | null) => void
}

export const useUserStore = create<UserState>()(
  persist(
    set => ({
      user: null,
      setUser: (user: User) => set({ user }),
      removeUser: () => set({ user: null }),
      userLoggedIn: false,
      setUserLoggedIn: (state: boolean) => set({ userLoggedIn: state }),
      userSessionStart: null,
      setUserSessionStart: (date: Date | null) =>
        set({ userSessionStart: date }),
    }),
    {
      name: "vs-user-storage",
    }
  )
)
