import { useMutation } from "@apollo/client"
import { REFRESH_AUTH_TOKEN } from "~/lib/queries"
import { useUserStore } from "~/lib/stores/user"
import useInterval from "./useInterval"

export default function useRefreshToken() {
  const currentUser = useUserStore(state => state.user)
  const setUserLoggedIn = useUserStore(state => state.setUserLoggedIn)
  const removeUser = useUserStore(state => state.removeUser)

  const [refreshAuthTokenMutation, { data, loading, error }] = useMutation(
    REFRESH_AUTH_TOKEN,
    {
      variables: {
        refreshToken: currentUser?.refreshToken,
      },
      onError(error) {
        console.error(error)
        removeUser()
        setUserLoggedIn(false)
      },
    }
  )

  useInterval(() => {
    if (currentUser?.refreshToken) {
      refreshAuthTokenMutation()
    }
  }, 1000 * 60)

  if (error) {
    removeUser()
    setUserLoggedIn(false)
  }

  if (!currentUser) return null

  return {
    data: data?.refreshJwtAuthToken?.authToken,
    loading,
    error,
  }
}
