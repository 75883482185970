import { useState } from "react"
import { useUserStore } from "~/lib/stores/user"
import { maxSessionLength } from "~/lib/constants"
import useInterval from "./useInterval"
import useSessionEnd from "./useSessionEnd"

export function timeDiff(start: Date, end: Date) {
  return end.getTime() - start.getTime()
}

export default function useSession() {
  const currentSessionStart = useUserStore(state => state.userSessionStart)

  const { endSession, sessionExpired } = useSessionEnd()
  const [sessionLength, setSessionLength] = useState(
    currentSessionStart
      ? timeDiff(new Date(currentSessionStart), new Date())
      : null
  )

  const [userSessionActive, setUserSessionActive] = useState(
    currentSessionStart ? true : false
  )

  useInterval(
    () => {
      if (currentSessionStart) {
        setSessionLength(timeDiff(new Date(currentSessionStart), new Date()))
      }
      if (sessionLength && sessionLength > maxSessionLength) {
        setUserSessionActive(false)
        endSession()
        sessionExpired()
        setSessionLength(null)
      }
    },
    currentSessionStart ? 2000 : null
  )

  return userSessionActive
}
