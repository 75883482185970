import { useQuery, gql } from "@apollo/client"
import React, { createContext, useContext, ReactNode, useEffect } from "react"
import { RawUser, User, AuthData } from "~/utils/types"
import useRefreshToken from "./useRefreshToken"
import { ToastContainer } from "react-toastify"
import useSession from "./useSession"
import { useUserStore } from "~/lib/stores/user"

const DEFAULT_STATE: AuthData = {
  loggedIn: false,
  user: undefined,
  loading: false,
  error: undefined,
}

const AuthContext = createContext(DEFAULT_STATE)

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      username
      firstName
      lastName
      email
      userGroup {
        group
        linkedSupplier {
          ... on Supplier {
            id
            title
            slug
            databaseId
            supplierDetails {
              emailAddress
              profilePicture {
                mediaItemUrl
                fileName
              }
            }
          }
        }
        linkedMember {
          ... on Member {
            id
            title
            slug
            memberDetails {
              clinicId
              postcode
            }
          }
        }
      }
    }
  }
`

export const formatUser = (data: RawUser): User => {
  if (!data) return data

  let linkedGroupData

  if (data?.user.userGroup?.group === "members") {
    linkedGroupData =
      data?.user.userGroup?.linkedMember &&
      data?.user.userGroup?.linkedMember[0]
  } else if (data?.user.userGroup?.group === "suppliers") {
    linkedGroupData =
      data?.user.userGroup?.linkedSupplier &&
      data?.user.userGroup?.linkedSupplier[0]
  }

  return {
    id: data?.user.id,
    name: data?.user.firstName
      ? `${data?.user.firstName} ${data?.user.lastName}`
      : data?.user.username,
    email: data?.user.email,
    userGroup: data?.user.userGroup?.group || "none",
    linkedGroupData,
    token: data?.authToken,
    refreshToken: data?.refreshToken,
  }
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const currentUser = useUserStore(state => state.user)
  const loggedIn = useUserStore(state => state.userLoggedIn)
  const storeUser = useUserStore(state => state.setUser)

  const token = useRefreshToken()
  const activeSession = useSession()

  const { data, loading, error } = useQuery(GET_USER, {
    skip: !currentUser,
    variables: { id: currentUser?.id },
    onCompleted(data) {
      if (!data?.user) return

      const formattedUser = formatUser(data)

      if (!formattedUser) return

      storeUser({
        ...formattedUser,
        refreshToken: currentUser?.refreshToken,
        token: currentUser?.token,
      })
    },
  })

  // update user store with new token
  useEffect(() => {
    if (token?.data && token?.data !== currentUser?.token) {
      storeUser({ ...currentUser, token: token.data })
    }
  }, [token?.data])

  const value = {
    loggedIn: loggedIn,
    user: currentUser,
    loading: false,
    error: undefined,
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
      <ToastContainer />
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export default useAuth
