import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import Button from "../reusable/Button"
import Loader from "../Loader"
import useSessionEnd from "~/hooks/useSessionEnd"
import { useUserStore } from "~/lib/stores/user"
import { maxSessionLength } from "~/lib/constants"
import { Slide, toast } from "react-toastify"
import { navigate } from "gatsby"
import { timeDiff } from "~/hooks/useSession"

export const LOG_OUT = gql`
  mutation logOut {
    logout(input: {}) {
      status
    }
  }
`

const LogOut = () => {
  const currentSessionStart = useUserStore(state => state.userSessionStart)
  const { endSession, sessionEnded } = useSessionEnd()
  const [sessionLength, setSessionLength] = useState<Number | null>(
    currentSessionStart
      ? timeDiff(new Date(currentSessionStart), new Date())
      : null
  )

  const [userSessionActive, setUserSessionActive] = useState<boolean>(
    currentSessionStart ? true : false
  )

  const [logOut, { loading }] = useMutation(LOG_OUT, {
    onCompleted(data) {
      if (data?.logout?.status === "SUCCESS") {
        endSession()
        sessionEnded()
      }
    },
    onError(error) {
      console.log(error?.graphQLErrors)
      if (error?.graphQLErrors?.includes("invalid-jwt")) {
        endSession()
        sessionEnded()
      }
    },
  })

  function handleLogOut() {
    if (currentSessionStart) {
      setSessionLength(timeDiff(new Date(currentSessionStart), new Date()))
    }
    if (sessionLength && sessionLength > maxSessionLength) {
      setUserSessionActive(false)
      endSession()
      toast.error("Your session has expired. Please log in again.", {
        transition: Slide,
        autoClose: 5000,
        hideProgressBar: true,
        onClose: () => {
          navigate("/")
        },
      })
    }
    logOut()
  }

  return (
    <>
      <Button
        type={"button"}
        text={loading ? <Loader loading={true} size={20} /> : "Log out"}
        buttonColor={"orange"}
        disabled={loading}
        onClick={() => handleLogOut()}
        className={`hidden md:inline-block`}
      />
      <button
        className={
          "block w-full p-3 text-left text-normal font-medium transition-all hover:underline md:hidden"
        }
        disabled={loading}
        onClick={() => handleLogOut()}
      >
        {loading ? <Loader loading={true} size={20} /> : "Log out"}
      </button>
    </>
  )
}

export default LogOut
