import fetch from "cross-fetch"
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { useUserStore } from "./stores/user"

const httpLink = createHttpLink({
  uri: process.env.GATSBY_WORDPRESS_API_URL,
  fetch,
})

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem("vs-user-storage") || "{}")
    ?.state?.user?.token

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
